import ClickEvent = JQuery.ClickEvent;
import {has} from "immutable";

(function () {
  const $cmp = document.getElementsByClassName('cmp-news-events-list');
  console.log("$cmp$cmp", $cmp);
  if ($cmp.length === 0) {
    return;
  }

  Array.prototype.map.call($cmp, (item: HTMLElement) => {
    const $select = item.querySelector('.aui-select');
    const $selectInput = $select?.querySelector('.aui-select__input') as HTMLInputElement;

    $select?.addEventListener("change", function (e) {
      const selectedValue = $selectInput?.value;
      const $currentTabContent = item.querySelector('.tab-content.active') as HTMLElement;


      if (selectedValue === 'all') {
        const $current = $currentTabContent.getElementsByClassName('pane')[0] as HTMLElement;
        $current.style.display = 'block';
      } else {
        const $current = $currentTabContent.getElementsByClassName('pane')[0] as HTMLElement;
        $current.style.display = 'none';
        const $showCurrent = $currentTabContent.querySelector(`.pane[data-value="${selectedValue}"]`) as HTMLElement;
        $showCurrent.style.display = 'block';
      }
    });

    const imgAll = document.querySelectorAll('.img1');
    const imgs = Array.from(imgAll) as HTMLElement[];
    window.addEventListener('scroll', () => {
      imgs.forEach(img => {
        if (img.hasAttribute('data-src')) {
          const height = window.innerHeight * 1.5;
          if (img.getBoundingClientRect().top < height) {
            const dataSrc = img.getAttribute('data-src') as string;
            img.setAttribute('src', dataSrc);
            img.removeAttribute('data-src');
          }
        }
      });
    });

    // const resetSelection = function (): void {
    //   $selectInput.value = "all";
    //   // $selectInput.dispatchEvent("reset");
    //   const $currentTab = item.querySelector(".tab-content.active")?.querySelector(".pane") as HTMLElement;
    //   $currentTab.style.display = 'show';
    // };

    // const $tabs = item.getElementsByClassName("newsevents-tab");
    // Array.prototype.map.call($tabs, ($tab) => {
    //   const $nav = $tab.querySelector(".tab-nav");
    //   const $tabLinks = $nav?.querySelectorAll(".tab_links") as NodeListOf<HTMLElement>;
    //
    //   const linkClickHandle = (e: ClickEvent): void => {
    //     Array.prototype.map.call($tabLinks, ($tabLink) => {
    //       if ($tabLink === e.currentTarget) {
    //         e.currentTarget.classList.add("active");
    //         const type = e.currentTarget.dataset.type;
    //         setTabContent(type);
    //       } else {
    //         $tabLink.classList.remove("active");
    //       }
    //     });
    //   };
    //
    //   const setTabContent = (type: string) => {
    //     const $content = item.querySelectorAll(`.${type}`);
    //     if ($content.length === 0) {
    //       return false;
    //     }
    //     const $contents = item.querySelectorAll(".tab-content");
    //     Array.prototype.map.call($contents, ($current: HTMLElement, index) => {
    //       if ($current.classList.contains(type)) {
    //         $current.classList.add("active");
    //         $current.style.display = "block";
    //       } else {
    //         $current.classList.remove('active');
    //         $current.style.display = "none";
    //       }
    //     });
    //     resetSelection();
    //   };
    //
    //   // Array.prototype.map.call($tabLinks, ($tabLink: HTMLElement, index) => {
    //   //   console.log("$tabLinks", $tabLinks);
    //   //   $tab.addEventListener("click", function (e: ClickEvent) {
    //   //     linkClickHandle(e);
    //   //   });
    //   // });
    // });
  });
})();
